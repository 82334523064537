import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, HashRouter, useLocation } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./auth/authProviderMsalReact";
import Health from "./Pages/Health";
import { RumInit } from "@maersk-global/telemetry-web-sdk";
//---------------
import RequestInterceptor from "./hooks/RequestInterceptor";
import { initializeRUM } from "./hooks/initializeRUM";
const msalInstance = new PublicClientApplication(msalConfig);

initializeRUM();

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/health" element={<Health />} />
    </Routes>

    <MsalProvider instance={msalInstance}>
      <RequestInterceptor>
        <App />
      </RequestInterceptor>
    </MsalProvider>
  </BrowserRouter>,
  // </AzureAD>,
  document.getElementById("root")
);

// Before SSO
/*
ReactDOM.render(
  <BrowserRouter>
  <App />
</BrowserRouter>,
  document.getElementById('root')
);
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
