import React, { memo, useEffect } from "react";
import InfoSharpIcon from "@mui/icons-material/InfoSharp";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useSloInfoForm } from "./Logic";
import { Alert } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { RingLoader } from "react-spinners";
import "../../../../../../StyleSheets/SlothSloInfo.css";
import SloDashBoardLink from "../../../../../../Components/SloDashBoardLink";
import CloseIcon from "@mui/icons-material/Close";
import UseSloInfo from "../../SlothContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
function SloInfo({ navigate }) {
  const {
    sloName,
    sloDescription,
    category,
    objective,
    errorQuery,
    totalQuery,
    alertName,
    alertAnnotations,
    editing,
    slos,
    alertPopUp,
    alertContent,
    alertSeverity,
    show,
    yamlString,
    yamlIsCorrect,
    sloData,
    loading,
    noOfOldSlos,
    onlyViewSloInfo,
    setYamlIsCorrect,
    handleClose,
    setAlertSeverity,
    setAlertContent,
    setAlertPopUp,
    setObjective,
    selectThisCategory,
    removeThisSlo,
    editThisSlo,
    submitForm,
    slothContextData,
    generateYaml,
    CalculateAllowedDowntime,
    sendYamlWithAPI,
    reinitialised,
    setShow,
    viewThisSlo,
    clearForm,
    errorQueryValidation,
    totalQueryValidation,
    displayError,
    displayErrorMessage,
    setSloName,
    setSloDescription,
    defaultSlo
  } = useSloInfoForm();
  console.log("NO of Old Slos", noOfOldSlos);
  useEffect(() => {
    slothContextData();
  }, []);
  const resetAlert = () => {
    setAlertPopUp(false);
    setAlertContent("");
    setAlertSeverity("");
    setYamlIsCorrect(false);
    reinitialised();
  };
  const handleFileSubmit = () => {
    if (!alertPopUp) {
      console.log("Sending Yamls");
      sendYamlWithAPI();
    } else {
      reinitialised();
    }
  };
  console.log("defaultSlo ", defaultSlo, sloDescription, sloName)

  const { updating, slosContextArray } = { ...UseSloInfo() };
  console.log("slosContextArraySLOInfo", slosContextArray);
  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Body
          style={{
            overflowY: "scroll",
            height: "10rem",
            padding: "20px",
            fontSize: "18px",
          }}
        >
          {alertPopUp && !loading ? (
            <Alert
              style={{}}
              severity={alertSeverity}
              onClose={() => {
                resetAlert();
              }}
            >
              {alertContent}
            </Alert>
          ) : (
            <div className="sloInfo__spinner">
              <RingLoader size={20} color="#003D6D" />
              <div></div>
              <p>
                {updating ? "Updating your YAML..." : "Submiting  your YAML..."}
              </p>
            </div>
          )}
          {alertSeverity === "success" ? (
            <p>
              <SloDashBoardLink
                appName={sloData.Application}
                env={sloData.Environment}
              />
            </p>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "space-around" }}>
          {alertSeverity !== "" && (
            <button
              style={{ width: "30%" }}
              className="sloinfoform__submitButton"
              onClick={handleFileSubmit}
            >
              Close
            </button>
          )}
        </Modal.Footer>
      </Modal>
      <div className="sloinfoform__contentArranger">
        <form className="sloinfoform" onSubmit={submitForm}>
          {onlyViewSloInfo && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <button
                type="button"
                onClick={() => clearForm()}
                style={{
                  width: "100px",
                  marginTop: "0px",
                  marginBottom: "10px",
                  padding: "5px",
                  marginRight: "20px",
                  borderRadius: "3px",
                }}
                className="sloinfoform__submitButton"
              >
                Clear Form
              </button>
            </div>
          )}

          <div className="sloinfoform__sloinfoContainer">
            <div className="sloinfoform__inputContainer">
              <label className="sloinfoform__label">
                What is the name of your SLO ?
              </label>
              <input
                className="sloinfoform__input"
                placeholder="component_name-slo_name"
                disabled={onlyViewSloInfo || defaultSlo}
                value={sloName}
                onChange={(e) => setSloName(e.target.value)}
              ></input>
              <div style={{ textAlign: "left", width: "100%" }}>
                {" "}
                <InfoSharpIcon style={{ fontSize: "15px", color: "#058EC6" }} />
                <p className="sloth__sloinfoformInfo">
                  Please use text inputs only
                </p>
              </div>
            </div>
            <div className="sloinfoform__inputContainer">
              <label className="sloinfoform__label">SLO Description ? </label>
              <input
                className="sloinfoform__input"
                placeholder="eg: SLO based on availability for HTTP request responses"
                disabled={onlyViewSloInfo || defaultSlo}
                value={sloDescription}
                onChange={(e) => setSloDescription(e.target.value)}
              ></input>
              <div
                style={{ textAlign: "left", width: "100%", fontSize: "10px" }}
              >
                {" "}
                <InfoSharpIcon style={{ fontSize: "15px", color: "#058EC6" }} />
                <p className="sloth__sloinfoformInfo">
                  Describe your SLO in words
                </p>
              </div>
            </div>
            <div className="sloinfoform__inputContainer">
              <label className="sloinfoform__label">
                What is your SLO Category?
              </label>
              <div className="choiceDiv__container">
                <div
                  onClick={
                    onlyViewSloInfo
                      ? null
                      : () => selectThisCategory("Availability")
                  }
                  className={
                    category === "Availability"
                      ? "sloinfoform__ChoiceDivSelected"
                      : "sloinfoform__ChoiceDivUnselected"
                  }
                >
                  Availability
                </div>
                <div
                  onClick={
                    onlyViewSloInfo ? null : () => selectThisCategory("Latency")
                  }
                  className={
                    category === "Latency"
                      ? "sloinfoform__ChoiceDivSelected"
                      : "sloinfoform__ChoiceDivUnselected"
                  }
                >
                  Latency
                </div>
                <div
                  onClick={
                    onlyViewSloInfo
                      ? null
                      : () => selectThisCategory("Custom SLO")
                  }
                  className={
                    category === "Custom SLO"
                      ? "sloinfoform__ChoiceDivSelected"
                      : "sloinfoform__ChoiceDivUnselected"
                  }
                >
                  Custom SLO
                </div>
              </div>
            </div>
            <div className="sloinfoform__inputContainer">
              <label className="sloinfoform__label">
                What is your target objective?
              </label>
              <input
                onChange={(e) => setObjective(e.target.value)}
                value={objective}
                type="text"
                id="score"
                disabled={onlyViewSloInfo || defaultSlo}
                pattern="^(\d{1,2}(\.\d{1,2})?|100(\.0{1,2})?)$"
                maxlength="7"
                placeholder="eg: 97.10"
                className="sloinfoform__input"
              ></input>
              <div
                style={{ textAlign: "left", width: "100%", fontSize: "10px" }}
              >
                <InfoSharpIcon style={{ fontSize: "15px", color: "#058EC6" }} />
                <p className="sloth__sloinfoformInfo">
                  Enter value less than or equal to 100
                </p>
              </div>
              <div className="sloinfo__sloDownTimeDivContainer">
                <div className="sloinfo__sloDownTimeDiv">
                  <label>Allowed Downtime Daily: </label>
                  <span style={{ display: "block" }}>
                    {CalculateAllowedDowntime(objective, 1)}
                  </span>
                </div>
                <div className="sloinfo__sloDownTimeDiv">
                  <label> Allowed Downtime Weekly: </label>
                  <span style={{ display: "block" }}>
                    {CalculateAllowedDowntime(objective, 7)}
                  </span>
                </div>
                <div className="sloinfo__sloDownTimeDiv">
                  <label>Allowed Downtime Monthly: </label>
                  <span style={{ display: "block" }}>
                    {CalculateAllowedDowntime(objective, 30)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="sloinfoform__sloinfoContainer">
            <h4 style={{ marginBottom: "20px" }}>SLI Definition</h4>
            <div className="sloinfoform__inputContainer">
              <label className="sloinfoform__label">Error Query</label>
              <div style={{ width: "100%", display: "flex" }}>
                <input
                  style={{ width: "100%", display: "flex" }}
                  ref={errorQuery}
                  className="sloinfoform__input"
                  placeholder="eg: sum(rate(http_request_duration_seconds_count{job=myservice, code = ~ (5..|429)[{{window}}]))"
                  disabled={onlyViewSloInfo}
                />
                {errorQueryValidation ? (
                  <div style={{ padding: "10px" }}>
                    <CloseIcon style={{ color: "red", fontSize: "20px" }} />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                style={{ textAlign: "left", width: "100%", fontSize: "10px" }}
              >
                {" "}
                <InfoSharpIcon style={{ fontSize: "18px", color: "#058EC6" }} />
                <p  style={{fontSize:"12px"}} className="sloth__sloinfoformInfo">
                  Please use range vector queries with an aggregation and
                  mention timeframes as 'window' variables <br />
                  Eg:{" "}
                  {
                    'sum(rate(http_request_duration_seconds_count{job="myservice",code=~"(5..|429)"}[{{.window}}]))'
                  }
                </p>
              </div>
            </div>
            <div className="sloinfoform__inputContainer">
              <label className="sloinfoform__label">Total Query</label>
              <div style={{ width: "100%", display: "flex" }}>
                <input
                  ref={totalQuery}
                  style={{ width: "100%", display: "flex" }}
                  className="sloinfoform__input"
                  placeholder="eg: sum(rate(http_request_duration_seconds_count{job=myservice[{{window}}]))"
                  disabled={onlyViewSloInfo}
                />
                {totalQueryValidation ? (
                  <div style={{ padding: "10px" }}>
                    <CloseIcon style={{ color: "red", fontSize: "20px" }} />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                style={{ textAlign: "left", width: "100%", fontSize: "10px" }}
              >
                {" "}
                <InfoSharpIcon style={{ fontSize: "15px", color: "#058EC6" }} />
                <p style={{fontSize:"12px"}} className="sloth__sloinfoformInfo">
                  Please use range vector queries with an aggregation and
                  mention timeframes as 'window' variables <br />
                  Eg:{" "}
                  {`sum(rate(http_request_duration_seconds_count{job="myservice"}[{{.window}}]))`}
                </p>
              </div>
            </div>
            {displayError ? (
              <div style={{ border: "3px solid red" }} className="p-2 m-2">
                <h3 style={{ color: "red", textAlign: "center" }}>ERROR</h3>
                <p style={{ fontSize: "14px" }}>{displayErrorMessage}</p>
                {/* display error message */}
              </div>
            ) : (
              ""
            )}
          </div>
          {sloData?.headwigScope?.length != 0 ? (
            <>
              <div className="sloinfoform__sloinfoContainer">
                <div className="sloinfoform__inputContainer">
                  <h4 style={{ marginBottom: "20px" }}>
                    Please Define alerts for SLO breaches
                  </h4>
                  <label className="sloinfoform__label">SLO Alert Name?</label>
                  <input
                    ref={alertName}
                    className="sloinfoform__input"
                    placeholder="MyServiceHighErrorRate"
                    disabled={
                      onlyViewSloInfo || sloData?.headwigScope?.length == 0
                    }
                  />
                </div>
                <div className="sloinfoform__inputContainer">
                  <label className="sloinfoform__label">
                    SLO Alert Annotations, if any?
                  </label>
                  <input
                    ref={alertAnnotations}
                    className="sloinfoform__input"
                    placeholder="eg: High error rate on 'MyService' request response "
                    disabled={
                      onlyViewSloInfo || sloData?.headwigScope?.length == 0
                    }
                  />
                  <div
                    style={{
                      textAlign: "left",
                      width: "100%",
                      fontSize: "10px",
                    }}
                  >
                    {" "}
                    <InfoSharpIcon
                      style={{ fontSize: "15px", color: "#058EC6" }}
                    />
                    <p className="sloth__sloinfoformInfo">
                      Please use text inputs only
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="sloinfoform__submitButtonContainer">
            {editing === true ? (
              <button
                onClick={onlyViewSloInfo ? () => clearForm() : submitForm}
                className="sloinfoform__submitButton"
              >
                Save
              </button>
            ) : (
              <button
                type="button"
                onClick={onlyViewSloInfo ? () => clearForm() : submitForm}
                className="sloinfoform__submitButton"
              >
                {onlyViewSloInfo ? "Clear Form" : "Add SLO"}
              </button>
            )}
          </div>
        </form>
        <div style={{ position: "fixed", width: "30%", right: "40px" }}>
          <div
            className={
              slos.length == 0
                ? "sloinfo__slosConatinerWithNoSlos"
                : "sloinfo__slosContainer"
            }
          >
            {slos.length == 0 ? (
              <h1 style={{ marginTop: "auto", marginBottom: "auto" }}>
                Please add some SLOs{" "}
              </h1>
            ) : (
              slos.map((slo, sloIndx) => {
                console.log(slo.newSlo);
                return (
                  <div
                    onClick={() =>
                      !slo.newSlo ? viewThisSlo(slo.sloName, slo.newSlo) : null
                    }
                    className="sloinfo__sloContainer"
                  >
                    {slo.sloName}
                    {slo.newSlo ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "8%",
                        }}
                      >
                        <EditIcon
                          onClick={() => editThisSlo(slo.sloName)}
                          style={{ color: "#003D6D", fontSize: "15px" }}
                        />
                        <DeleteIcon
                          onClick={() => removeThisSlo(sloIndx, slo.sloName)}
                          style={{ color: "#003D6D", fontSize: "15px" }}
                        />
                      </div>
                    ) : (
                      <VisibilityIcon
                        onClick={() => viewThisSlo(slo.sloName, slo.newSlo)}
                        style={{ color: "#003D6D", fontSize: "15px" }}
                      />
                    )}
                  </div>
                );
              })
            )}
          </div>
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <button
              type="button"
              onClick={handleFileSubmit}
              disabled={
                slos.length === 0 ||
                editing === true ||
                noOfOldSlos === slos.length
              }
              className="sloinfoform__submitButton"
            >
              Submit SLOs
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default memo(SloInfo);
