import React, { useEffect, useState } from "react";
import "./style.css";
import { Link, useLocation } from "react-router-dom";
import DropDown from "../Assets/DropDown.svg";
import DropDownUp from "../Assets/DropDownUp.svg";
import Bullet from "../Assets/bullets_lists.svg";
import Points from "../Assets/points.svg";

function SideBar() {
  const location = useLocation();
  const [currPath, setcurrPath] = useState("");
  const [currDropDown, setCurrDropDown] = useState("");
  const [toggle, setToggle] = useState(false);

  const DropDownHandler = (option) => {
    setCurrDropDown(option);
  }

  const FileValidationDropDown = (currToggle) => {
    if (currToggle) {
      setToggle(false);
      // DropDownHandler("FileValidation");
      DropDownHandler("CertficateValidation");
    }
    else {
      setToggle(true);
      // DropDownHandler("CertficateValidation");
      DropDownHandler("FileValidation");
    }
  }

  useEffect(() => {
    if (location.pathname === "/CertficateValidation") {
      setcurrPath("CertficateValidation");
    } else if (location.pathname === "/with_pensive_validation") {
      setcurrPath("with_pensive_validation");
    } else if (location.pathname === "/ValidationForUrls") {
      setcurrPath("ValidationForUrls");
      setCurrDropDown("ValidationForUrls");
    } else if (location.pathname === "/forensic") {
      setcurrPath("forensic");
    } else if (location.pathname === "/ipace") {
      setcurrPath("ipace");
    } else if (location.pathname === "/slodashboarding") {
      setcurrPath("slodashboarding");
    } else if (location.pathname === "/slo-service") {
      setcurrPath("slodashboarding");
    } else if (location.pathname === "/status_page") {
      setcurrPath("status_page");
    } else if (location.pathname === "/runbook_template") {
      setcurrPath("runbook_template")
    } else if (location.pathname === "/dashoboard") {
      setcurrPath("dashoboard");
    } else if (location.pathname === "/validation_script") {
      setcurrPath("validation_script");
    } else if (location.pathname === "/autotron") {
      setcurrPath("autotron");
    } else {
      setcurrPath("/");
      setCurrDropDown("");
    }
  }, [location.pathname]);

  console.log("currPath : ", currPath);
  console.log("currDropDown : ", currDropDown);

  return (
    <div className="home-menu-wrapper" onClick={() => { setcurrPath("") }}>
      <div className="homeMenu" >

        <Link className="link" to="/autotron">
          <div
            className="menu-item"
            style={{
              display: "flex",
              cursor: "pointer",
              backgroundColor: currPath === "autotron" ? "#91D8F2" : "",
              color: currPath === "autotron" ? "black" : "",
              borderTop: "1px solid #005487"
            }}
            onClick={() => DropDownHandler("")}
          >
            <img style={{ width: "16px" }} src={Points} alt="points" />
            <p style={{ fontSize: "18px", marginLeft: "10px" }}>Autotron</p>
          </div>
        </Link>

        <Link className="link" to="/CertficateValidation">
          <div
            className="menu-item extra_class"
            style={{
              display: "flex",
              cursor: "pointer",
              backgroundColor: currPath === "CertficateValidation" ? "#91D8F2" : "" || currDropDown === "CertficateValidation" ? "#91D8F2" : "",
              color: currPath === "CertficateValidation" ? "black" : "" || currDropDown === "CertficateValidation" ? "black" : "",
            }}

          >
            <img src={Points} style={{ width: "16px" }} alt="ponits" />
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              <p style={{ fontSize: "18px", marginLeft: "10px" }}>Certificate Validation</p>
              {currDropDown !== "CertficateValidation" && <img
                src={DropDown}
                style={{ width: "20px" }}
                alt="Drop-Down-logo"
                onClick={() => DropDownHandler("CertficateValidation")}
              />}
              {currDropDown === "CertficateValidation" && <img
                src={DropDownUp}
                style={{ width: "20px" }}
                alt="Drop-Down-logo"
                onClick={() => DropDownHandler("")}
              />}
            </div>
          </div>
        </Link>

        {(currDropDown === "CertficateValidation" || currDropDown === "ValidationForUrls" || currDropDown === "FileValidation") && <div
          className="link"
        >
          <div
            className="menu-item extra_class"
            style={{
              display: "flex",
              cursor: "pointer",
              // backgroundColor: currPath === "FileValidation" ? "#91D8F2" : "",
              // color: currPath === "FileValidation" ? "black" : "",
              backgroundColor: currPath === "FileValidation" ? "#91D8F2" : "" || currDropDown === "FileValidation" ? "#91D8F2" : "",
              color: currPath === "FileValidation" ? "black" : "" || currDropDown === "FileValidation" ? "black" : "",
              paddingBottom: "6px"
            }}
            onClick={() => FileValidationDropDown(toggle)}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src={Points} style={{ marginLeft: "10px", width: "20px" }} alt="ponits" />
              <p style={{ fontSize: "14px", marginLeft: "15px" }}>File Validation with Pensieve Integration</p>
            </div>
            {currDropDown !== "FileValidation" && <img
              src={DropDown}
              style={{ width: "20px" }}
              alt="Drop-Down-logo"
              // onClick={() => DropDownHandler("FileValidation")}
            />}
            {currDropDown === "FileValidation" && <img
              src={DropDownUp}
              style={{ width: "20px" }}
              alt="Drop-Down-logo"
              // onClick={() => DropDownHandler("CertficateValidation")}
            />}
          </div>
        </div>}

        {currDropDown === "FileValidation" && <a className="link" href="https://grafana.maersk.io/d/rhoK5d5Vz/sre-model-dashboards-sre-certificate-expiry-model-dashboard?orgId=1&refresh=1m" target="_blank">
          <div
            className="menu-item inner_child"
            style={{
              display: "flex",
              cursor: "pointer",
              backgroundColor: currPath === "dashoboard" ? "#91D8F2" : "",
              color: currPath === "dashoboard" ? "black" : "",
              borderBottom: currDropDown === "FileValidation" ? "1px solid #005487" : "",
            }}
          >
            <img src={Points} style={{ width: "20px", marginLeft: "20px" }} alt="ponits" />
            <p style={{ fontSize: "12px", marginLeft: "15px" }}>Dashboard</p>
          </div>
        </a>}

        {currDropDown === "FileValidation" && <a className="link" href="https://github.com/Maersk-Global/SRE-cert-check-script-telegraf" target="_blank">
          <div
            className="menu-item inner_child"
            style={{
              display: "flex",
              cursor: "pointer",
              backgroundColor: currPath === "validation_script" ? "#91D8F2" : "",
              color: currPath === "validation_script" ? "black" : "",
            }}
          >
            <img src={Points} style={{ width: "20px", marginLeft: "20px" }} alt="ponits" />
            <p style={{ fontSize: "12px", marginLeft: '15px' }}>Validation Script</p>
          </div>
        </a>}


        {(currDropDown === "CertficateValidation" || currDropDown === "FileValidation" || currDropDown === "ValidationForUrls") && <Link className="link" to="/ValidationForUrls">
          <div
            className="menu-item"
            style={{
              display: "flex",
              cursor: "pointer",
              backgroundColor: currPath === "ValidationForUrls" ? "#91D8F2" : "",
              color: currPath === "ValidationForUrls" ? "black" : "",
              borderTop: currDropDown === "FileValidation" ? "1px solid #005487" : "",
            }}
          >
            <img src={Points} style={{ marginLeft: "10px", width: "20px" }} alt="points" />
            <p style={{ fontSize: "14px", marginLeft: "15px" }}>Validation for URLs</p>
          </div>
        </Link>}

        <Link className="link" to="/forensic">
          <div
            className="menu-item"
            style={{
              display: "flex",
              cursor: "pointer",
              backgroundColor: currPath === "forensic" ? "#91D8F2" : "",
              color: currPath === "forensic" ? "black" : "",
            }}
            onClick={() => DropDownHandler("")}
          >
            <img src={Points} style={{ width: "16px" }} alt="ponits" />
            <p style={{ fontSize: "18px", marginLeft: "10px" }}>Forensic</p>
          </div>
        </Link>

        <a className="link" href={`${process.env.REACT_APP_LOADSTAR}`} target="_blank">
          <div
            className="menu-item"
            style={{
              display: "flex",
              cursor: "pointer",
              backgroundColor: currPath === "ipace" ? "#91D8F2" : "",
              color: currPath === "ipace" ? "black" : "",
            }}
            onClick={() => DropDownHandler("")}
          >
            <img style={{ width: "16px" }} src={Points} alt="points" />
            <p style={{ fontSize: "18px", marginLeft: "10px" }}>iPACE</p>
          </div>
        </a>

        <a className="link" href={`${process.env.REACT_APP_POSTMORTEM}`} target="_blank">
          <div
            className="menu-item"
            style={{
              display: "flex",
              cursor: "pointer",
              backgroundColor: currPath === "postmortem" ? "#91D8F2" : "",
              color: currPath === "postmortem" ? "black" : "",
            }}
            onClick={() => DropDownHandler("")}
          >
            <img style={{ width: "16px" }} src={Points} alt="points" />
            <p style={{ fontSize: "18px", marginLeft: "10px" }}>Postmortem</p>
          </div>
        </a>

        <a className="link" href={`${process.env.REACT_APP_SMART_MUTE}`} target="_blank">
          <div
            className="menu-item"
            style={{
              display: "flex",
              cursor: "pointer",
              backgroundColor: currPath === "postmortem" ? "#91D8F2" : "",
              color: currPath === "postmortem" ? "black" : "",
            }}
            onClick={() => DropDownHandler("")}
          >
            <img style={{ width: "16px" }} src={Points} alt="points" />
            <p style={{ fontSize: "18px", marginLeft: "10px" }}>SmartMute</p>
          </div>
        </a>

        <a className="link" href={`${process.env.REACT_APP_CMDB_PORTAL}`} target="_blank">
          <div
            className="menu-item"
            style={{
              display: "flex",
              cursor: "pointer",
              backgroundColor: currPath === "postmortem" ? "#91D8F2" : "",
              color: currPath === "postmortem" ? "black" : "",
            }}
            onClick={() => DropDownHandler("")}
          >
            <img style={{ width: "16px" }} src={Points} alt="points" />
            <p style={{ fontSize: "18px", marginLeft: "10px" }}>CMDB Portal</p>
          </div>
        </a>

        <a className="link" href={`${process.env.REACT_APP_SRE_SKYDECK_LIBRARY_SITE}`} target="_blank">
          <div
            className="menu-item"
            style={{
              display: "flex",
              cursor: "pointer",
              backgroundColor: currPath === "runbooks_templates" ? "#91D8F2" : "",
              color: currPath === "runbooks_templates" ? "black" : "",
            }}
            onClick={() => DropDownHandler("")}
          >
            <img src={Points} style={{ width: "16px" }} alt="ponits" />
            <p style={{ fontSize: "18px", marginLeft: "10px" }}> Runbooks and Templates</p>
          </div>
        </a>

        <Link className="link" to="/slodashboarding">
          <div
            className="menu-item"
            style={{
              display: "flex",
              cursor: "pointer",
              backgroundColor: currPath === "slodashboarding" ? "#91D8F2" : "",
              color: currPath === "slodashboarding" ? "black" : "",
            }}
            onClick={() => DropDownHandler("")}
          >
            <img src={Points} style={{ width: "16px" }} alt="ponits" />
            <p style={{ fontSize: "18px", marginLeft: "10px" }}>SLO as a Service</p>
          </div>
        </Link>

        <a className="link" href={`${process.env.REACT_APP_STATUS_PAGE}`} target="_blank">
          <div
            className="menu-item"
            style={{
              display: "flex",
              cursor: "pointer",
              backgroundColor: currPath === "status_page" ? "#91D8F2" : "",
              color: currPath === "status_page" ? "black" : "",
            }}
            onClick={() => DropDownHandler("")}
          >
            <img src={Points} style={{ width: "16px" }} alt="ponits" />
            <p style={{ fontSize: "18px", marginLeft: "10px" }}>Status Page</p>
          </div>
        </a>

      </div>
    </div >
  );
}

export default SideBar;
