import { RumInit } from "@maersk-global/telemetry-web-sdk";

export const initializeRUM = () => {
  const faro = RumInit({
    app: {
      name:
        process.env.REACT_APP_ENV === "PROD"
          ? "SkyDeck-Prod"
          : process.env.REACT_APP_ENV === "STAGE"
          ? "SkyDeck-Stage"
          : "SkyDeck-Dev",
      version: "1.0",
    },
    apiKey: process.env.REACT_APP_RUM_KEY,
    debug: true, // false: Prevent showing log,error,etc captured in browser console
    traceLog: true, // Sends Traces to Loki ( For easy querying of traces)
    sso_user: true,
    instrumentations: {
      console: true, //
      interactions: true,
      // or
      // interactions: {
      //   eventNames: ['click','mousedown']
      // }
      xhr: false,
    },
  });
  return faro;
};
